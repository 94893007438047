
































import { Component, Prop, Vue } from 'vue-property-decorator';
// import flipstarters from '@/flipstarters.json';
import { getFlipstarters } from '@/flipstarter';
import CampaignJumbotron from '@/components/CampaignJumbotron.vue';
import Intro from '@/components/Intro.vue';
import Loading from '@/components/Loading.vue';

Vue.filter('status', (status: string) => {
  //
  const uppercaseFirst = status.charAt(0).toUpperCase() + status.slice(1);
  return `Status: ${uppercaseFirst}`;
});

@Component({
  components: {
    CampaignJumbotron,
    Intro,
    Loading,
  },
})
export default class HelloWorld extends Vue {
  @Prop() private msg!: string;

  private loadingFlipstarters = true;

  private error = '';

  show = {
    completed: 3,
    running: 15,
  }

  // eslint-disable-next-line
  flipstarters: any = [];

  get running() {
    if (this.flipstarters) {
      return this.flipstarters.filter((f: { status: string }) => f.status === 'running');
    }
    return [];
  }

  get completed() {
    if (this.flipstarters) {
      return this.flipstarters.filter((f: { status: string }) => {
        const s = f.status;
        return s === 'success' || s === 'expired';
      });
    }
    return [];
  }

  get upcoming() {
    if (this.flipstarters) {
      return this.flipstarters.filter((f: { status: string }) => f.status === 'upcoming');
    }
    return [];
  }

  created() {
    // eslint-disable-next-line
    getFlipstarters().then((data: any) => {
      this.flipstarters = data;
    }).catch((error) => {
      this.error = error.message;
    }).then(() => {
      this.loadingFlipstarters = false;
    });
  }
}
