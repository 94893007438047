import axios from 'axios';

// Set VUE_APP_FLIPSTARTER_DIRECTORY_API variables in .env files
// see https://cli.vuejs.org/guide/mode-and-env.html
// see .env.production.example for an example for production deployment
const baseURL = process.env.VUE_APP_FLIPSTARTER_DIRECTORY_API || 'https://flipbackend.bitcoincash.network';

interface Campaign {
  amount: number;
  url: string;
  title: string;
  description: string;
  status: string;
  fundedTx: string;
  categories: Record<string, string>[];
  announcements: Record<string, string>[];
  archives: Record<string, string>[];
}

const getFlipstarters = async () => {
  const response = await axios.get(`${baseURL}/v1/flipstarter/?old`);
  return response.data;
};

const getCategories = async () => {
  const response = await axios.get(`${baseURL}/v1/flipstarter-category/`);
  return response.data;
};

const submitFlipstarter = async (data: Campaign) => {
  const response = await axios.post(`${baseURL}/v1/flipstarter/`, data);
  return response.data;
};

const fetchFlipstarterDataFromUrl = async (url: string) => {
  const response = await axios.post(`${baseURL}/v1/flipstarter-data-from-url/`, { url });
  return response.data;
};

export {
  Campaign,
  fetchFlipstarterDataFromUrl,
  getCategories,
  getFlipstarters,
  submitFlipstarter,
};
