

































import { Component, Vue } from 'vue-property-decorator';
import { getFlipstarters } from '@/flipstarter';

Vue.filter('round', (value: number, decimals: number) => Math.round(value * (10 ** decimals)) / (10 ** decimals));

@Component
export default class About extends Vue {
  private succesfulCount = -1;

  private totalFunded = -1;

  created() {
    getFlipstarters().then((flipstarters) => {
      // eslint-disable-next-line
      const successful = flipstarters.filter((f: any) => f.status === 'success');
      // eslint-disable-next-line
      this.totalFunded = successful.reduce((a: any, b: any) => {
        const aa = typeof a === 'number' ? a : a.amount;
        const bb = typeof b === 'number' ? b : b.amount;
        return aa + bb;
      });
      this.succesfulCount = successful.length;
    }).catch((error) => {
      console.log(error);
    });
  }
}
