
























































import {
  Watch, Component, Prop, Vue,
} from 'vue-property-decorator';
import { getTransaction } from '../electrum';

Vue.filter('txToURL', (tx: string) => {
  const url = `https://explorer.bitcoinunlimited.info/tx/${tx}`;
  return url;
});

Vue.filter('blockToURL', (block: string) => {
  const url = `https://explorer.bitcoinunlimited.info/block-height/${block}`;
  return url;
});

Vue.filter('shortID', (txid: string) => {
  const shortID = `${txid.slice(0, 6)}...${txid.slice(60)}`;
  return shortID;
});

@Component
export default class CampaignJumbotron extends Vue {
  // eslint-disable-next-line
  @Prop() private f!: any;

  /* eslint-disable-next-line */
  private txDetails: any = null;

  private maxPledge: number | string = -1.0;

  private minPledge: number | string = -1.0;

  private medianPledge: number | string = -1.0;

  private completedTime = '';

  async calculatePledges() {
    /* eslint-disable-next-line */
    const inputTxs = await Promise.all(this.txDetails.vin.map((i: any) => getTransaction(i.txid)));

    const amounts: number[] = [];
    for (let i = 0; i < this.txDetails.vin.length; i += 1) {
      /* eslint-disable-next-line */
      const tx: any = inputTxs[i];
      if (tx === null || tx instanceof Error) {
        console.log('Failed to calculate pledge amounts', tx);
        return;
      }
      /* eslint-disable-next-line */
      const vout = this.txDetails.vin[i].vout;
      // console.log(vout, this.txDetails.vin[i], tx.vout[vout]);
      amounts.push(tx.vout[vout].value_satoshi);
    }
    amounts.sort();
    this.maxPledge = (Math.max(...amounts) / 100000000).toFixed(6);
    this.minPledge = (Math.min(...amounts) / 100000000).toFixed(6);

    const median = (arr: number[]) => {
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    };

    this.medianPledge = median(amounts);
    this.medianPledge = (this.medianPledge / 100000000).toFixed(6);
  }

  public async created() {
    await this.updatePledgeTransaction();
  }

  private async updatePledgeTransaction() {
    this.completedTime = '';
    this.maxPledge = -1;
    this.medianPledge = -1;
    this.minPledge = -1;
    this.txDetails = null;
    if (!this.f.tx) {
      return;
    }
    try {
      const details = await getTransaction(this.f.tx);
      if (details instanceof Error) {
        throw details;
      }
      this.txDetails = details;
      if (!this.txDetails) {
        return;
      }
      this.completedTime = (new Date(this.txDetails.blocktime * 1000)).toLocaleDateString();
      await this.calculatePledges();
    } catch (e) {
      console.info('getTranscation', e);
    }
  }

  @Watch('f')
  public async onFlipstarterChange() {
    await this.updatePledgeTransaction();
  }
}
