

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Loading extends Vue {
  @Prop(Boolean) readonly loading: boolean = true;

  @Prop(String) readonly error: string = '';
}
