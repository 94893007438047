import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Flipstarters on Bitcoin Cash',
    },
  },
  {
    path: '/active',
    name: 'Active',
    // route level code-splitting
    // this generates a separate chunk (active.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Active.vue'),
    meta: {
      title: 'Active - Flipstarters on Bitcoin Cash',
    },
  },
  {
    path: '/completed',
    name: 'Completed',
    // route level code-splitting
    // this generates a separate chunk (completed.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Completed.vue'),
    meta: {
      title: 'Completed - Flipstarters on Bitcoin Cash',
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About - Flipstarters on Bitcoin Cash',
    },
  },
  {
    path: '/submit',
    name: 'Submit',
    // route level code-splitting
    // this generates a separate chunk (submit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "submit0" */ '../views/Submit.vue'),
    meta: {
      title: 'Submit - Submit a new Flipstarter Campaign',
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
