import { ElectrumClient, ElectrumTransport, RequestResponse } from 'electrum-cash';

let connectPromise: Promise<void> | null = null;
let electrum: ElectrumClient | null = null;
(async () => {
  const tmp = new ElectrumClient(
    'flipstarters.bitcoincash.network',
    '1.4.2', 'bitcoincash.network',
    ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme,
  );
  try {
    connectPromise = tmp.connect();
    await connectPromise;
  } catch (e) {
    console.log('Failed to connect ', e);
  }
  electrum = tmp;
})();

window.addEventListener('unload', async () => {
  try {
    if (electrum !== null) {
      await electrum.disconnect();
    }
  } catch (e) {
    console.error('Disconnect error', e);
  }
});

/* eslint-disable-next-line */
export async function getTransaction(txid: string): Promise<RequestResponse> {
  if (electrum === null) {
    if (connectPromise !== null) {
      await connectPromise;
      return getTransaction(txid);
    }
    console.log('Electrum not ready, and not connecting.');
    return null;
  }
  return electrum.request('blockchain.transaction.get', txid, true);
}

export async function dummyFunc() {
  /* to make 'prefer default export' go away */
}
