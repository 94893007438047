const scrollBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

const ensureHttp = (url: string) => {
  let ensuredUrl = url;
  if (!url.match(/^(http|https):\/\/.+/)) {
    ensuredUrl = `https://${ensuredUrl}`;
  }
  return ensuredUrl;
};

export { ensureHttp, scrollBottom };
